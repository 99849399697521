<template>
  <div>
    <div class="circle-input"
      :style="{ width: size + 'px', height: size + 'px', backgroundColor: color, borderColor: color }">
      <div class="input">{{ value }}</div>
    </div>
    <div class="mt-2">
      days left
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: 100, // Default size
    },
    color: {
      type: String,
      default: "white", // Default circle border color
    },
    value: {
      type: [String, Number],
      default: 0, // Value to display
    },
  },
};
</script>

<style scoped>
.circle-input {
  border: 4px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input {
  font-size: 1.0em;
  text-align: center;
  width: 60%;
  background: none;
  color: white;
  font-weight: bold;
}
</style>