<template>

  <div class="mt-4">
    <div class="" v-if="show_sum_payments">
      <div>
        There are {{ no_payments }} payments between {{ start }} and {{ end }}
      </div>
      <div>

        <div class="d-flex flex-row">
          <div>
            <h5>Summary of payments by type</h5>
            <vue-good-table :columns="paymentColumns" :rows="sum_payments" :sort-options="{
              enabled: false,
            }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'aamount'">
                  {{ props.row.aamount.toLocaleString() }}
                </span>
                <span v-else-if="props.column.field == 'eamount'">
                  {{ props.row.eamount.toLocaleString() }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <div class="ml-3 mt-3">
            <span>Insurance expect = insurance paid in payment + insurance gop in out standing</span><br>
            <span>Insurance actual = insurance paid in payment</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 mt-4">
      <h3 class="text-primary">Payment</h3>
    </div>
    <div class="mt-3">
      <payment-table :data="payments" @displayPaymentModal="showPayment" />
    </div>


  </div>
</template>

<script>
import { db } from '../../db'
import { capitalizeFirstChar } from '../../helper'
import moment from 'moment'
export default {
  props: ['start', 'end'],
  data() {
    return {
      payments: [],
      no_payments: 0,
      additionalInsuranceAmount: 0,
      paymentColumns: [
        {
          label: "Payment type",
          field: "payment_type",
          thClass: 'text-center',

        },
        {
          label: "Expect Amount",
          field: "eamount",
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: "Actual Amount",
          field: "aamount",
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      sum_payments: [
        {
          payment_type: 'Cash',
          eamount: 0,
          aamount: 0,
        },
        {
          payment_type: 'Credit/Debit card',
          eamount: 0,
          aamount: 0,
        },
        {
          payment_type: 'Internet Banking',
          eamount: 0,
          aamount: 0,
        },
        {
          payment_type: 'Insurance',
          eamount: 0,
          aamount: 0,
        },
        {
          payment_type: 'Other',
          eamount: 0,
          aamount: 0,
        },
        {
          payment_type: 'Total',
          eamount: 0,
          aamount: 0,
        }
      ],
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const start = this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate()
      const end = this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate()
      const payments = []

      const paymentsCollection = await db.collection('Payment')
        .where('issue_date', '>', start)
        .where('issue_date', '<', end)
        .get()
      const invoicesCollection = await db.collection('Invoice')
        .where('issue_date', '>', start)
        .where('issue_date', '<', end)
        .get()


      const isBranch03 = this.$store.state.branch === 3
      const filter_payment = paymentsCollection.docs.filter(doc => {
        const idPrefix = doc.data().payment_id.slice(0, 2)
        return isBranch03 ? idPrefix === "03" : idPrefix !== "03"
      })

      const filter_invoice = invoicesCollection.docs.filter(doc => {
        const idPrefix = doc.data().invoice_id.slice(0, 2)
        return isBranch03 ? idPrefix === "03" : idPrefix !== "03"
      })

      this.no_payments = filter_payment.length
      this.additionalInsuranceAmount = 0
      filter_invoice.forEach(invoice => {
        const data = invoice.data()
        if (data.status === 'gop') {
          this.additionalInsuranceAmount += data.total_invoiced - data.payment_applied
        }
      })

      filter_payment.forEach(paymentCollection => {
        const payment = paymentCollection.data()
        const preparedPayments = []
        if (payment.cash > 0) preparedPayments.push({ name: 'Cash', amount: payment.cash })
        if (payment.credit > 0) preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        if (payment.insurance > 0) preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        if (payment.internet_banking > 0) preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        if (payment.other > 0) preparedPayments.push({ name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other })

        payment.total_amount = _.sum([payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking])
        const rawString = payment.VN
        const dateString = rawString.substring(rawString.lastIndexOf('-') + 1)
        const visit_date = moment(`${parseInt(dateString.substring(0, 2))}/${dateString.substring(2, 4)}/${dateString.substring(4)}`, "DD/MM/YYYY")
        payment.visit_date = visit_date
        payment.preparedPayments = preparedPayments

        payments.push(payment)
      })

      this.initDataforTable(payments)

      const paymentsByDate = _.groupBy(payments, payment => this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY"))
      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments,
      }))

      loader.hide()
    },
    initDataforTable(payments) {

      const paymentTypes = ['cash', 'credit', 'internet_banking', 'insurance', 'other']
      paymentTypes.forEach((type, index) => {
        this.sum_payments[index].eamount = _.sumBy(payments, type);
        this.sum_payments[index].aamount = _.sumBy(payments, type);
      })
      this.sum_payments[3].eamount += this.additionalInsuranceAmount;
      this.sum_payments[5].eamount = _.sumBy(this.sum_payments.slice(0, 5), 'eamount')
      this.sum_payments[5].aamount = _.sumBy(this.sum_payments.slice(0, 5), 'aamount')
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },



  },
  watch: {
    start() {
      this.fetchPayments()
      this.sum_payments[0].eamount = 0
      this.sum_payments[1].eamount = 0
      this.sum_payments[2].eamount = 0
      this.sum_payments[3].eamount = 0
      this.sum_payments[4].eamount = 0
      this.sum_payments[5].eamount = 0
      this.sum_payments[0].aamount = 0
      this.sum_payments[1].aamount = 0
      this.sum_payments[2].aamount = 0
      this.sum_payments[3].aamount = 0
      this.sum_payments[4].aamount = 0
      this.sum_payments[5].aamount = 0

    },


  },
  computed: {
    show_sum_payments() {
      if (this.start !== this.end) {
        if (this.$store.getters.permission['Invoice'] === 5) {
          return true
        }
        else {
          return false
        }
      }
      return true
    },
  }
}
</script>