import { render, staticRenderFns } from "./ObservePatientSummaryTable.vue?vue&type=template&id=19bfb920"
import script from "./ObservePatientSummaryTable.vue?vue&type=script&lang=js"
export * from "./ObservePatientSummaryTable.vue?vue&type=script&lang=js"
import style0 from "./ObservePatientSummaryTable.vue?vue&type=style&index=0&id=19bfb920&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports