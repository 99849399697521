<template>
    <div>
        <h5 class="text-primary mt-2">List Manager</h5>
        <div class="mb-3">
            <b-dropdown text="Select List" variant="primary">
                <b-dropdown-item v-for="group in groups" :key="group" @click="setActiveGroup(group)">
                    {{ group | capitalize }}
                </b-dropdown-item>
            </b-dropdown>
            {{ activeGroup | capitalize }}
        </div>

        <vue-good-table :columns="activeColumns" :rows="activeList" :sort-options="{ enabled: false }"
            style="width: 25%;" @on-row-click="showData">
            <div slot="table-actions">
                <button class="btn btn-primary btn-block" @click="showData()">
                    <i class="fas fa-plus"></i> Add
                </button>
            </div>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'is_auditer' && props.row.is_auditer">
                    <i class="fa fa-check text-success" aria-hidden="true"></i>
                </div>
            </template>
        </vue-good-table>
        <b-modal hide-footer size="sm" :title="'Add ' + activeGroup | capitalize" id="register-modal">
            <div class="row">
                <div class="col-12">
                    <MazInput :placeholder="'Name'" v-model="item.value" />
                </div>
                <div class="col-12" v-if="activeGroup === 'insurance_lists' || activeGroup === 'publicHealth_lists'">
                    <b-form-checkbox size="lg" v-model="item.is_auditer">Auditer</b-form-checkbox>
                </div>
                <div class="col-12 mt-2">
                    <button class="btn btn-primary btn-block" @click="saveData">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
                <div class="col-12 mt-2" v-if="isEdit">
                    <button class="btn btn-danger btn-block" @click="deleteData">
                        <i class="fas fa-trash"></i> Delete
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { capitalize } from "lodash";
import { db } from "../../db"
export default {
    data() {
        return {
            activeGroup: "doctor_lists",
            groups: ["doctor_lists", "nurse_lists", "paramedic_lists", "insurance_lists", "publicHealth_lists"],
            lists: {},
            item: { label: "", value: "" },
            columns: [
                { label: "Name", field: "value" },
            ],
            columns_audit: [
                { label: "Name", field: "value" },
                { label: "Auditer", field: "is_auditer" }
            ],
            isEdit: false,

        };
    },
    computed: {
        activeList() {
            return this.lists[this.activeGroup] || []
        },
        activeColumns() {

            if (["insurance_lists", "publicHealth_lists"].includes(this.activeGroup)) {
                return this.columns_audit
            }
            return this.columns
        },
    },
    created() {

        this.fetchAllData()
    },
    methods: {
        async fetchAllData() {
            try {
                const promises = this.groups.map(async (group) => {
                    const snapshot = await db.collection(group).get()
                    this.$set(this.lists, group, snapshot.docs.map((doc) => doc.data()))
                });

                // Wait for all promises to resolve
                await Promise.all(promises)
                console.log("All data fetched successfully:", this.lists)
            } catch (error) {
                console.error("Error fetching data:", error)
                this.$alert(`Error fetching data: ${error.message}`, null, "error")
            }
        },
        setActiveGroup(group) {
            this.activeGroup = group

        },
        showData(params) {
            if (params) {
                this.item = { label: params.row.value, value: params.row.value }
                if (this.activeGroup === 'insurance_lists' || this.activeGroup === 'publicHealth_lists') {
                    this.item = { label: params.row.value, value: params.row.value, is_auditer: params.row.is_auditer }
                }
                this.isEdit = true
            } else {
                this.item = { label: "", value: "" }
                if (this.activeGroup === 'insurance_lists' || this.activeGroup === 'publicHealth_lists') {
                    this.item = { label: "", value: "", is_auditer: false }
                }
                this.isEdit = false
            }
            this.$bvModal.show("register-modal")
        },
        async saveData() {
            const loader = this.$loading.show({})
            try {
                this.item.label = this.item.value
                const data = { ...this.item }
                await db.collection(this.activeGroup).doc(this.item.value).set(data)
                await this.fetchAllData()
                loader.hide()
                this.$alert("Data saved successfully.", null, "success")
                this.$bvModal.hide("register-modal")
            } catch (error) {
                this.$alert(`Error: ${error.message}`, null, "error")
                loader.hide()
            }
        },
        deleteData() {
            this.$confirm("Are you sure you want to delete this?").then(async () => {
                const loader = this.$loading.show({})
                try {
                    await db.collection(this.activeGroup).doc(this.item.label).delete()
                    await this.fetchAllData()
                    loader.hide()
                    this.$bvModal.hide("register-modal")
                    this.$alert("Item deleted successfully.", null, "success")
                } catch (error) {
                    this.$alert(`Error: ${error.message}`, null, "error")
                    loader.hide()
                }
            });
        },
    },
    filters: {
        capitalize(value) {
            if (!value) return ""
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
};
</script>
