<template>
    <div>
        <section class="sheet padding-10mm ">
            <div class="d-flex mt-2 justify-content-between">
                <div>
                    <h3 class="text-primary">Product Summary</h3>
                </div>
                <div>
                    <span>{{ showThisTime() }}</span>
                </div>
            </div>

            <div class="row mt-4 d-flex flex-column">
                <vue-good-table :columns="stockColumns" :rows="prepared_products" :use-dynamic-row-height="true"
                    :sort-options="{
                        enabled: false,
                    }" :group-options="{
                        enabled: false,
                    }" :pagination-options="{
                        dropdownAllowAll: false,
                    }" class="stock-table">
                    <template slot="table-row" slot-scope="props">

                        <div v-if="props.column.field == 'item_name'" style="display: block;">
                            <span style="display: block;">{{ props.row.item_name }}</span>
                            <span style="font-size: smaller; text-decoration: underline; display: block;">
                                {{ props.row.item_other_name }}
                            </span>
                        </div>
                        <span v-else-if="props.column.field === 'expire_date'">
                            {{
                                formatDate(props.row.expire_date)

                            }}
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>

                    </template>
                </vue-good-table>
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../../db"
import moment from "moment"
export default {
    data() {
        return {
            stocks: [],
            stockColumns: [
                { label: "Item Code", field: "item_code" },
                { label: "Item Name", field: "item_name" },

                { label: "Branch", field: "branch" },
                {
                    label: "Expire Date", field: "expire_date",

                    tdClass: (row) => { return this.changeColorClass(row.expire_date) }
                },
                { label: "Quantity", field: "quantity", thClass: 'text-right', tdClass: 'text-right' },
            ],

        }
    },
    firestore() {
        return {
            //stocks: db.collection("Stock").orderBy('expire_date', 'asc'),
        }
    },
    mounted() {
        document.body.className = 'A4'
        this.fetchStocks()

    },
    methods: {
        async fetchStocks() {
            const querySnapshot = await db.collection('Stock').orderBy('expire_date', 'asc').get()
            this.stocks = querySnapshot.docs.map(doc => doc.data())
        },
        changeColorClass(value) {
            let givenDate = moment(value, "YYYY-MM-DD hh:mm A")
            let currentDate = moment()
            let diffInDays = givenDate.diff(currentDate, 'days')
            if (diffInDays < 90) {
                return 'bg-red'
            } else if (diffInDays >= 90 && diffInDays < 180) {
                return 'bg-yellow'
            } else {
                return ''
            }
        },
        formatDate(dateString) {
            dateString = dateString.replaceAll('-', '/').slice(0, 10)
            const myArray = dateString.split('/')
            dateString = myArray[2] + '/' + myArray[1] + '/' + myArray[0]
            return dateString
        },
        showThisTime() {
            return moment().format('DD/MM/YYYY, h:mm:ss')
        },
        getBranchName(branchId) {

            const branchNames = { 0: 'Main Stock' }
            this.$store.state.branches.forEach(branch => {
                branchNames[branch.id] = branch.name_en
            })
            return branchNames[branchId] || 'Unknown'
        },
    },
    computed: {
        prepared_products() {
            const currentDate = moment()
            let filterRed = false
            let filterYellow = false
            if (this.$route.query.filter_red === 'true') {
                filterRed = true
            }
            if (this.$route.query.filter_yellow === 'true') {
                filterYellow = true
            }
            return this.stocks
                .filter((row) => {
                    const diffInDays = moment(row.expire_date, "YYYY-MM-DD hh:mm A").diff(currentDate, 'days');
                    if (filterRed && filterYellow) return diffInDays < 180;
                    else if (filterRed) return diffInDays < 90;
                    else if (filterYellow) return diffInDays >= 90 && diffInDays < 180;
                    return true; // If no filters, return all
                })
                .map((row) => ({
                    ...row,
                    branch: this.getBranchName(row.branch),
                }));
        }
    }

}
</script>
<style lang="scss">
@page {
    size: portrait;
}

.sheet {
    overflow: visible;
    height: auto !important;
}

.stock-table {
    .bg-red {
        background-color: #FF4C4C;
        color: white;
    }

    .bg-yellow {
        background-color: #FFEB3B;
        color: black;
    }
}
</style>