<template>
  <div>
    <div class="print" v-if="$route.meta.print">
      <router-view />
    </div>
    <div class="main d-flex" v-else>
      <worldmed-sidebar v-if="$route.path !== '/login'"></worldmed-sidebar>
      <div class="right-box">
        <div class="d-flex" v-if="$store.getters.user">
          <div class="mr-auto"></div>
          <div class="mt-2 mr-2 text-muted">
            <span class="badge badge-primary" v-if="$store.state.branch===1">{{ $store.state.branches[$store.state.branch-1].name_en }}</span>
            <span class="badge badge-danger" v-if="$store.state.branch===2">{{ $store.state.branches[$store.state.branch-1].name_en }}</span>
            <span class="badge badge-info" v-if="$store.state.branch===3">{{ $store.state.branches[$store.state.branch-1].name_en }}</span>
            <span class="badge badge-primary" v-if="$store.state.branch===4">{{ $store.state.branches[$store.state.branch-1].name_en }}</span>
            <span class="badge badge-info ml-2" v-for="(role, index) in $store.getters.user.assigned_roles" :key="index">{{ role.name }}</span>
            &nbsp;{{ $store.getters.user.displayName }}
            <button class="btn btn-danger btn-sm ml-2" @click="logout">
              <i class="fas fa-sign-out-alt"></i>
            </button>
          </div>
        </div>
        <router-view class="mt-2"/>
      </div>
    </div>

    <div
      class="print-btn shadow"
      v-if="$route.meta.print"
      @click.prevent="print"
    >
      <i class="fas fa-print"></i>
    </div>

    <!-- <div class="version-update shadow" v-if="current_version && ($store.getters.current_version !== current_version.version)">
      <button class="btn btn-primary" @click.prevent="updateVersion(current_version.version)"><i class="fas fa-sync-alt"></i> Update</button> to v.{{ current_version.version }}
    </div> -->
  </div>
</template>

<script>
import { fb, db } from "./db";
export default {
  data() {
    return {
      current_version: null,
      subscribed_roles: []
    }
  },
  firestore() {
    return {
      current_version: db.collection('Config').doc('current_version')
    }
  },
  async created() {
    fb.auth().onAuthStateChanged(async user => {
      if (!user) {
        //unsubscribe roles 
        for(let subrole of this.subscribed_roles) {
          subrole();
        }
        this.subscribed_roles = []

        this.$store.commit("setUser", null);

        return;
      }

      let userInfo = {
        uid: user.uid,
        displayName: user.displayName || user.email.split("@")[0],
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL
      };
      await db
        .collection("User")
        .doc(user.uid)
        .set(userInfo, { merge: true });
      let fetchedUser = (
        await db
          .collection("User")
          .doc(user.uid)
          .get()
      ).data();
      if (!fetchedUser.isApproved) {
        this.$store.commit("setUser", null);
        this.$router.push("/login");
      } else {
        this.$store.commit("setUser", fetchedUser);

        //get user role
        for(let role of fetchedUser.assigned_roles) {
          let subRole = db.collection("Role").doc(role.id)
            .onSnapshot((doc) => {
              let roleData = {
                id: role.id,
                ...doc.data()
              }
              this.$store.commit("addRole", roleData)
            });
          this.subscribed_roles.push(subRole)
        }
        this.initData();
      }
    });
    if (!this.$store.getters.user && this.$route.path !== "/login") {
      this.$router.push("/login");
    }
  },
  methods: {
    print() {
      window.print();
    },
    async initData() {
      // let loader = this.$loading.show({});
      let branches = (await db.collection("Branch").get()).docs.map(b => {
        return {
          id: b.id,
          ...b.data()
        };
      });
      let departments = (await db.collection("Department").get()).docs.map(
        d => {
          return {
            id: d.id,
            ...d.data()
          };
        }
      );
      let places = (await db.collection("Place").get()).docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      let insurances = (await db.collection("Insurance").get()).docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      let assist_insurances = (await db.collection("AssistInsurance").get()).docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      let allergies = (await db.collection("Allergy").get()).docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      let reactions = (await db.collection("Reaction").get()).docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      let nurses = (await db.collection("User").where('roles', 'array-contains', 'RN').get()).docs.map(item => {
        return {
          id: item.id,
          ...item.data()
        };
      });


      // Other
      let dd_procedure = (await db.collection("Procedure").get()).docs.map(
        b => {
          return {
            id: b.id,
            ...b.data()
          };
        }
      );
      let dd_medicine = (await db.collection("Medicine").get()).docs.map(b => {
        return {
          id: b.id,
          ...b.data()
        };
      });
      let dd_supply = (await db.collection("Supply").get()).docs.map(b => {
        return {
          id: b.id,
          ...b.data()
        };
      });

      this.$store.commit("setBranches", branches);
      this.$store.commit("setDepartments", departments);
      this.$store.commit("setPlaces", places);
      this.$store.commit("setInsurances", insurances);
      this.$store.commit("setAssistInsurances", assist_insurances);
      this.$store.commit("setAllergies", allergies);
      this.$store.commit("setNurses", nurses);

      this.$store.commit("setDDProcedure", dd_procedure);
      this.$store.commit("setDDMedicine", dd_medicine);
      this.$store.commit("setDDSupply", dd_supply);
      this.$store.commit('setReactions', reactions)

      // loader.hide();
    },
    updateVersion(version) {
      console.log(`[+]update to version ${version}`)
      this.$store.commit("setCurrentVersion", version);
      location.reload();
    },
    logout() {
      this.$confirm("do you want to logout?").then(async () => {
        await fb.auth().signOut();
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    user() {
      if (!this.user) {
        this.$router.push("/login");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
}

.right-box {
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}

.print-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border: 1px #adadad;
  background-color: #8c85f1;
  color: white;
  padding: 15px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 50px;
  font-size: 50px;
  cursor: pointer;
}

@media print {
  .print-btn {
    display: none !important;
  }

  .version-update {
    display: none;
  }
}

.version-update {
  position:fixed;
  padding-top: 15px;
  padding: 15px;
  border-radius: 0.5em;
  top: 25px;
  right: 25px;
  background-color: white;
}
</style>
