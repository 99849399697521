<template>
  <div class="sheet p-4">
    <div class="container-fluid">
      <div v-if="!patientId">
        <div class="d-flex flex-row-reverse" v-if="show_sum_payments">
          <div>
            <vue-good-table :columns="paymentColumns" :rows="sum_payments" :sort-options="{
              enabled: false,
            }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'aamount'">
                  {{ props.row.aamount.toLocaleString() }}
                </span>                
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <payment-table :data="payments" />
      <p class="text-grey mt-2 text-right" v-if="start && end">{{ $route.query.start }} to {{ $route.query.end }}</p>
    </div>
  </div>
</template>

<script>
import { db } from '../../db'
import { capitalizeFirstChar } from '../../helper'
import moment from 'moment'
export default {
  data() {
    return {
      payments: [],
      no_payments: 0,
      start: this.$route.query.start,
      end: this.$route.query.end,
      patientId: this.$route.query.patientId,
      paymentColumns: [
        {
          label: "Payment type",
          field: "payment_type",
          thClass: 'text-center',

        },
        {
          label: "Actual Amount",
          field: "aamount",
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      sum_payments: [
        {
          payment_type: 'Cash',
          aamount: 0,
        },
        {
          payment_type: 'Credit/Debit card',
          aamount: 0,
        },
        {
          payment_type: 'Internet Banking',
          aamount: 0,
        },
        {
          payment_type: 'Insurance',
          aamount: 0,
        },
        {
          payment_type: 'Other',
          aamount: 0,
        },
        {
          payment_type: 'Total',
          aamount: 0,
        }
      ],
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const start = this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate()
      const end = this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate()
      const payments = []

      const paymentsCollection = this.patientId ?
        await db.collection('Payment')
          .where('HN', '==', this.patientId)
          .get()
        :
        await db.collection('Payment')
          .where('issue_date', '>', start)
          .where('issue_date', '<', end)
          .get()

      

      const isBranch03 = this.$store.state.branch === 3
      const filter_payment = paymentsCollection.docs.filter(doc => {
        const idPrefix = doc.data().payment_id.slice(0, 2)
        return isBranch03 ? idPrefix === "03" : idPrefix !== "03"
      })      

      this.no_payments = filter_payment.length
      
      filter_payment.forEach(paymentCollection => {
        const payment = paymentCollection.data()
        const preparedPayments = []

        if (payment.cash > 0) preparedPayments.push({ name: 'Cash', amount: payment.cash })
        if (payment.credit > 0) preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        if (payment.insurance > 0) preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        if (payment.internet_banking > 0) preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        if (payment.other > 0) preparedPayments.push({ name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other })

        payment.total_amount = _.sum([payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking])
        const rawString = payment.VN
        const dateString = rawString.substring(rawString.lastIndexOf('-') + 1)
        const visit_date = moment(`${parseInt(dateString.substring(0, 2))}/${dateString.substring(2, 4)}/${dateString.substring(4)}`, "DD/MM/YYYY")
        payment.visit_date = visit_date
        payment.preparedPayments = preparedPayments

        payments.push(payment)
      })

      this.initDataforTable(payments)

      const paymentsByDate = _.groupBy(payments, payment => this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY"))

      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments,
      }))

      loader.hide()
    },
    initDataforTable(payments) {

      const paymentTypes = ['cash', 'credit', 'internet_banking', 'insurance', 'other']
      paymentTypes.forEach((type, index) => {
        this.sum_payments[index].aamount = _.sumBy(payments, type);
      })
      this.sum_payments[5].aamount = _.sumBy(this.sum_payments.slice(0, 5), 'aamount')
    },

  },
  computed: {
    show_sum_payments() {
      if(this.$route.query.start !== this.$route.query.end){
        if(this.$store.getters.permission['Invoice'] === 5){
          return true
        }
        else {
          return false
        }
      }
      return true
    },
  }
}
</script>

<style type="text/css" media="print">
@page {
  size: landscape !important
}
</style>