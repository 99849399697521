import Setting from '../pages/Setting/index.vue'
import Branch from '../pages/Setting/Branch.vue'
import Role from '../pages/Setting/Role.vue'
import Zone from '../pages/Setting/Zone.vue'
import Hotel from '../pages/Setting/Hotel.vue'
import Staff from '../pages/Setting/Staff.vue'

export default [
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    redirect: { name: "Setting Branch" },
    children: [
      // {
      //   path: "",
      //   name: "Setting Role",
      //   component: Role
      // },
      {
        path: "/branch",
        name: "Setting Branch",
        component: Branch
      },
      
      {
        path: "/zone",
        name: "Setting Zone",
        component: Zone
      },
      {
        path: "/hotel-list",
        name: "Setting Hotel",
        component: Hotel
      },
      {
        path: "/staff-list",
        name: "Staff list",
        component: Staff
      },
      
    ]
  }
];
