<template>
  <div>
    <section class="sheet padding-10mm invoice-form">
      <div class="d-flex row mt-2 justify-content-between">
        <h3 class="text-primary">Inventory Report</h3>
        <span> {{ $route.query.start }} - {{ $route.query.end }}</span>
      </div>

      <div class="row mt-4 d-flex flex-column">
        <h4 class="text-primary">Summary Of Revenue</h4>
        <vue-good-table :columns="summaryColumns" :rows="[...serviceSummary, ...productSummary, ...totalSummary]"
          :use-dynamic-row-height="true" styleClass="table condensed table-bordered vgt-table-report-print"
          :sort-options="{
            enabled: false,
          }" :group-options="{
            enabled: false,
          }" :pagination-options="{
            dropdownAllowAll: false,
          }" />
      </div>

      <div class="row mt-4 d-flex flex-column">
        <h4 class="text-primary">Service Revenue</h4>
        <vue-good-table :columns="serviceColumns" :rows="[...services, ...serviceSummary]"
          :use-dynamic-row-height="true" styleClass="table condensed table-bordered vgt-table-report-print"
          :sort-options="{
            enabled: false,
          }" :group-options="{
            enabled: false,
          }" :pagination-options="{
            dropdownAllowAll: false,
          }" />
      </div>

      <div class="row mt-4 d-flex flex-column">
        <h4 class="text-primary">Product Revenue</h4>
        <vue-good-table :columns="serviceColumns" :rows="[...products, ...productSummary]"
          :use-dynamic-row-height="true" styleClass="table condensed table-bordered vgt-table-report-print"
          :sort-options="{
            enabled: false,
          }" :group-options="{
            enabled: false,
          }" :pagination-options="{
            dropdownAllowAll: false,
          }" />
      </div>

    </section>
  </div>
</template>
<script>
import { db } from "../../db"
export default {
  data() {
    return {
      services: [],
      products: [],
      serviceColumns: [
        {
          label: "Item",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "Item Code",
          field: "item_code",
          thClass: 'text-left',
          tdClass: 'text-left',
          width: '140px',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '95px',
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '110px',
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '110px',
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '125px',
        },
      ],
      summaryColumns: [
        {
          label: "Type",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString() + " ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
      ],
    }
  },
  async mounted() {
    document.body.className = 'A4'
    this.fetchInvoices(
      this.$moment(this.$route.query.start, 'DD/MM/YYYY').startOf('day').toDate(),
      this.$moment(this.$route.query.end, 'DD/MM/YYYY').endOf('day').toDate()
    )
  },
  methods: {
    async fetchInvoices(start, end) {
      let services = []
      let products = []
      const invoicesRef = await db.collection('Invoice')
        .where('issue_date', '>=', start)
        .where('issue_date', '<', end)
        //.where('status', '==', 'paid')
        .where('status', 'in', ['paid', 'outstanding', 'gop'])
        .get()

      let filter_invoice = invoicesRef.docs
      if (this.$route.query.branch === 'all') {
        filter_invoice = invoicesRef.docs
      }
      else if (this.$route.query.branch === 'wm') {
        filter_invoice = invoicesRef.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) !== "03"
        })
      }
      else if (this.$route.query.branch === 'wa') {
        filter_invoice = invoicesRef.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) === "03"
        })
      }

      filter_invoice.forEach(invoice => {
        const data = invoice.data()
        services = services.concat(this.getServiceRevenue(data.billable_items))
        products = products.concat(this.getServiceRevenue(data.product_items))
      })

      const formattedServices = _.chain(services)
        .groupBy('item_code')
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, 'quantity'),
          amount: _.sumBy(service, 'amount'),
          discount: _.sumBy(service, 'discount'),
          total: _.sumBy(service, 'total'),
        })
        )

      const formattedProducts = _.chain(products)
        .groupBy('item_code')
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, 'quantity'),
          amount: _.sumBy(service, 'amount'),
          discount: _.sumBy(service, 'discount'),
          total: _.sumBy(service, 'total'),
        })
        )

      this.products = formattedProducts.value()
      this.services = formattedServices.value()
    },
    getServiceRevenue(services) {
      if (!services || !services.length) return []

      return services.map(item => {
        const amount = Number(item.price * item.quantity)
        const discount = this.getDiscount(item)
        return {
          item_code: item.item_code,
          item_name: item.item.item_name,
          quantity: item.quantity,
          amount,
          discount,
          total: amount - discount
        }
      })
    },
    getDiscount(item) {
      if (item.quantity && item.price) {
        return Math.round(Number(item.quantity) * Number(item.price) * (Number(item.discount) / 100))
      } else {
        return 0
      }
    },
    getSum(item, title) {
      return {
        item_name: title,
        quantity: _.sumBy(item, 'quantity'),
        amount: _.sumBy(item, 'amount'),
        discount: _.sumBy(item, 'discount'),
        total: _.sumBy(item, 'total'),
      }
    }
  },
  computed: {
    serviceSummary() {
      return [this.getSum(this.services, 'Service')]
    },
    productSummary() {
      return [this.getSum(this.products, 'Products')]
    },
    totalSummary() {
      return [this.getSum([...this.serviceSummary, ...this.productSummary], 'Total')]
    }
  }

}
</script>
<style lang="scss">
@page {
  size: portrait;
}

.sheet {
  overflow: visible;
  height: auto !important;
}

.vgt-table-report-print thead th {
  background-color: #2F6EBA;
  color: white;
}

.vgt-table-report-print tr:last-child td {
  background-color: #DAE2E8;
  color: black;
  font-weight: bold;
}

@media print {
  .vgt-table-report-print thead th {
    background-color: #2F6EBA !important;
    color: white !important;
  }

  .vgt-table-report-print tr:last-child td {
    background-color: #DAE2E8 !important;
    color: black !important;
    font-weight: bold;
  }
}
</style>