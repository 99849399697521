<template>
  <div>
    <input type="text" class="ctw-input" autocomplete="off" v-bind:data-ctw-ino="iNo" placeholder="Search icd11"/>    
    <div class="ctw-window" v-bind:data-ctw-ino="iNo"></div>
    <div>
      Code : <input type="text" class="ml-3 w-25 mt-3" v-model="code" @input="updateValue"/>
    </div>
    <div>
      Title : <input type="text" class="ml-3 w-75 mt-3 mb-3" v-model="title" @input="updateValue"/>
    </div>   
  </div>
</template>
<script>
import * as ECT from '@whoicd/icd11ect';
import '@whoicd/icd11ect/style.css';

export default {
  name: 'icd10select',
  props:  ["value","iNo"],
  data() {
    return {
      code: this.value ? this.value.code : null,
      title: this.value ? this.value.title : null,      
    }
  },
  created() {
    
    // configure the ECT
    const settings = {
      // the API located at the URL below should be used only for software development and testing
      apiServerUrl: 'https://icd11restapi-developer-test.azurewebsites.net',
      autoBind: false, // in Vue.js we recommend using the manual binding
    };
    const callbacks = {
      selectedEntityFunction: (selectedEntity) => {
        // shows an alert with the code selected by the user and then clears the search results
        //alert('ICD-11 code selected: ' + selectedEntity.code);     
        this.code = selectedEntity.code 
        this.title = selectedEntity.title
        this.updateValue()
        ECT.Handler.clear(this.iNo);
      },
    };
    ECT.Handler.configure(settings, callbacks);
  },
  mounted() {
    // manual binding only after the component has been mounted
    ECT.Handler.bind(this.iNo)    
  },
  methods: {
    updateValue() {
      this.$emit('input', { code: this.code, title: this.title })
    }
  },
};

</script>