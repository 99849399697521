<template>
    <div v-if="visible" class="toast-notification" @click="dismiss">
      <i class="icon-warning"></i> <!-- You can use any icon library or an image -->
      <span>There are patients whose observe time exceeds 6 hours!</span>
      <button class="close-button" @click.stop="dismiss">✖</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      dismiss() {
        this.$emit('dismiss')
      },
    },
  }
  </script>
  
  <style scoped>
  .toast-notification {
    position: fixed;
    top: 50px;
    right: 20px;
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.5s ease;
  }
  
  .icon-warning {
    margin-right: 10px;
    font-size: 20px; /* Adjust size as needed */
  }
  
  .close-button {
    background: none;
    border: none;
    color: #721c24;
    cursor: pointer;
    margin-left: 10px;
  }
  </style>