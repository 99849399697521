<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <h3 class="text-primary">Setting</h3>
        <p class="text-muted">worldmedcenter application setting</p>
        <hr />
        <b-nav tabs>
          <b-nav-item :active="$route.path === item.to" :to="item.to" v-for="item in menus"
            :key="item.name">{{ item.name }}</b-nav-item>
        </b-nav>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        // { name: 'Role', to: '/setting' },
        { name: 'Branch', to: '/branch' },        
        { name: 'Zone', to: '/zone' },
        { name: 'Hotel', to: '/hotel-list' },
        { name: 'Staff', to:'/staff-list' },
        
      ],
    }
  }
}
</script>

<style></style>