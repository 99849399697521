import { db } from '../db'
export default {
  data() {
    return {
      selectedDept: 1,
      selectedBranch: 1
    };
  },
  methods: {
    async newVisit(patientId, branch, department, visit_date, visit_type) {
      if (!branch || !department) return;
      let loader = this.$loading.show({});
      let VN = await this.generateVN(branch, department);

      let data = {
        VN,
        patient: db.collection("Patient").doc(patientId),
        updated_at: new Date(),
        branch: db.collection("Branch").doc(`${branch}`),
        department: db.collection("Department").doc(`${department}`)
      };

      if(visit_date) {
        data.visit_date = visit_date
      } else {
        data.visit_date = new Date()
      }
      
      if(visit_type) {
        data.visit_type = visit_type
      }else {
        data.visit_type = 'New Patient'
      }
      
      try {
        await db
          .collection("Visit")
          .doc(VN)
          .set(data);
        
        this.$router.push({
          name: "Nurse Assessment",
          params: {
            id: patientId,
            visitId: VN
          }
        });
      } catch (error) {
        console.log({error})
      }
      
      loader.hide();
    },
    async generateVN(branch, department) {
      let countVisitPatient = await db
        .collection("Visit")
        .where(
          "visit_date",
          ">",
          this.$moment()
            .startOf("day")
            .toDate()
        )
        .where(
          "visit_date",
          "<",
          this.$moment()
            .endOf("day")
            .toDate()
        )
        .get();
      let paddedCountVisitPatient = countVisitPatient.docs.length
        .toString()
        .padStart("3", "0");
      return `01-${branch}-${department}-${paddedCountVisitPatient}-${this.$moment().format(
        "DDMMYYYY"
      )}`;
    }
  }
};