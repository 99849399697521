var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"sheet padding-10mm"},[_c('div',{staticClass:"d-flex mt-2 justify-content-between"},[_vm._m(0),_c('div',[_c('span',[_vm._v(_vm._s(_vm.showThisTime()))])])]),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('vue-good-table',{staticClass:"stock-table",attrs:{"columns":_vm.stockColumns,"rows":_vm.prepared_products,"use-dynamic-row-height":true,"sort-options":{
                    enabled: false,
                },"group-options":{
                    enabled: false,
                },"pagination-options":{
                    dropdownAllowAll: false,
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'item_name')?_c('div',{staticStyle:{"display":"block"}},[_c('span',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(props.row.item_name))]),_c('span',{staticStyle:{"font-size":"smaller","text-decoration":"underline","display":"block"}},[_vm._v(" "+_vm._s(props.row.item_other_name)+" ")])]):(props.column.field === 'expire_date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.expire_date))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-primary"},[_vm._v("Product Summary")])])
}]

export { render, staticRenderFns }